body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Inter', sans-serif;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

/*
  3. Allow percentage-based heights in the application
*/
html, body,  #root {
  height: 100%;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  -webkit-font-smoothing: antialiased;
}



/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

html,
body {
  box-sizing: border-box;
  max-width: 100vw;
  overflow-x: hidden;

 
}


*,
*::after,
*::before {
  box-sizing: inherit;
}


/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}



/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}



.react-modal-class {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

@media only screen and (max-width: 1311px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1180px) {
  .react-modal-class {
    width: 50% !important;
  }
}

@media only screen and (max-width: 1080px) {
  html {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .react-modal-class {
    width: 70% !important;
  }
}


@media only screen and (max-width: 576px) {
  .react-modal-class {
    width: 90% !important;
    height: 26% !important;
  }
}





