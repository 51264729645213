.settingsTop-bgW{
    width:100%;
    height:25%;
    background-color:#FFFFFF;
    padding:15px;
    box-sizing:border-box
}

.settingsTop-bgB{
    width:100%;
    height:25%;
    background-color:#1B1C1C;
    padding:15px;
    box-sizing:border-box;
}